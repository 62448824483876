<template>
    <vs-row id="diensten" justify="center">
        <vs-col :w="wModule()" class="module box-shadow">
            <vs-row justify="center">
                <vs-col w="12"><div class="line"></div></vs-col>
                <vs-col w="12" class="space">
                    <div class="background">
                        <h1 class="title">Diensten | Electrotechniek</h1>
                    </div>
                </vs-col>

                <vs-col w="12">
                    <img class="landscape image" src="@/assets/images/electronics.jpg" alt="">
                </vs-col>

                <vs-col align="left" :w="w0()">
                    <h4>
                        MD Electro kent zijn oorsprong in de installatietechniek. Vanzelfsprekend vormen diensten op het gebied van het vakkundig inrichten en beheren van installaties dan 
                        ook nog steeds een belangrijk onderdeel van onze activiteiten. <br><br> De electrotechnische en werktuigbouwkundige installaties vormen een wezenlijk onderdeel van elk 
                        gebouw en moeten dan ook professioneel en naar behoren worden geïnstalleerd, zodat een gebouw verwarmd en verlicht wordt, voldoende stroompunten heeft en beschikt 
                        over stromend water en sanitaire voorzieningen. Ook een goede luchtkwaliteit, geventileerde ruimtes en verlichting die zich aanpast aan de hoeveelheid zonlicht, 
                        zorgen voor extra comfort. Hoewel dit logische basisvoorwaarden zijn voor elk gebouw, vergt de invulling daarvan gespecialiseerde kennis en expertise om deze 
                        installaties goed en efficiënt te laten werken.<br><br>
                        MD Electro helpt zijn opdrachtgevers de benodigde technische toepassingen optimaal in te vullen, van ontwerp en implementatie tot beheer. Met praktijkervaring in talrijke 
                        segmenten en actuele kennis van alle toepasselijke wet- en regelgeving, denkt MD Electro mee over de beste aanpak hoe de inrichting en afstelling van installaties de 
                        (primaire) processen van organisaties zoveel mogelijk versterken. Ook op het gebied van efficiency en (energie)kostenbesparing brengt MD Electro al jaren ervaring 
                        van de installatietechniek in. Het verlagen van de CO2-footprint gebeurt meestal niet van de ene op de andere dag, maar MD Electro geeft via een meerjarenplan een 
                        onderscheidend advies.<br><br>
                        Met behulp van een zogenaamd gebouwbeheerssysteem kan de werking van de technische voorzieningen in een pand eenvoudig dag en nacht gemonitord worden, ook op afstand. 
                        Zo zorgt MD Electro dat eventuele verstoringen snel gesignaleerd en verholpen worden, zodat opdrachtgevers zich kunnen richten op hun primaire bedrijfsactiviteiten.
                    </h4>
                </vs-col>

                <vs-col w="12"><div class="line"></div></vs-col>
            </vs-row>
        </vs-col>

        <DienstenTabbie/>
    </vs-row>
</template>

<script>
import DienstenTabbie from '../components/DienstenTabbie.vue'

    export default {
        data() {
            return {

            }
        },
        components: {
            DienstenTabbie
        },

        methods: {
        wModule() {
            return this.$store.getters.smallDevice ? 12 : 10
        },
        w0() {
            return this.$store.getters.smallDevice ? 12 : 10
        },
    },
    }
</script>

<style lang="sass" scoped>
.landscape
    display: block 
    width: 80%
    height: 350px
    object-fit: cover
    margin: auto

.contact-btn
    margin: auto
    border-radius: 10px

.module
    margin-bottom: 30px


</style>